<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <h3 class="p-2">
          ยอดเล่นสะสม
        </h3>
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="dateStart">วันเริ่มต้นยอดเล่น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="วันเริ่มต้นยอดเล่น"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="dateEnd">วันสิ้นสุดยอดเล่น</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="วันสิ้นสุดยอดเล่น"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="form-group"
            >
              <label>ชื่อสมัครชิก</label>
              <input
                v-model="search_userName"
                type="text"
                class="form-control"
                placeholder="ชื่อสมาชิก"
              >
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="form-group"
            >
              <label>รหัสสมาชิก</label>
              <input
                v-model="search_userID"
                type="text"
                class="form-control"
                placeholder="รหัสสมาชิก"
              >
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="form-group"
            >
              <label>ประเภท</label>
              <b-form-select
                v-model="selected"
                :options="options"
                @input="product_id = null; game_code = null;"
              />
            </div>
          </div>
          <div
            v-if="selected === '1'"
            class="col-md-2"
          >
            <div
              class="form-group"
            >
              <label>ค่ายเกมส์</label>
              <b-form-select
                v-model="product_id"
                :options="Product_list"
                @input="game_code = null;"
              />
            </div>
          </div>
          <div
            v-if="selected === '1'"
            class="col-md-2"
          >
            <div
              class="form-group"
            >
              <label>เกมส์</label>
              <b-form-select
                v-model="game_code"
                :options="FilterGame(product_id)"
              />
            </div>
          </div>
          <div
            v-if="selected === '2'"
            class="col-md-2"
          >
            <div
              class="form-group"
            >
              <label>หวย</label>
              <b-form-select
                v-model="game_code"
                :options="Lotto_list"
              />
            </div>
          </div>
          <!-- <div class="col-md-2">
            <div
              class="form-group"
            >
              <label>กระเป๋าเงิน</label>
              <input
                v-model="search_Wallet"
                type="number"
                class="form-control"
                placeholder="ยอดเงิน"
              >
            </div>
          </div> -->
          <!-- <div class="col-md-2">
            <div
              class="form-group"
            >
              <label>ยอดฝาก</label>
              <input
                v-model="search_DesAmt"
                type="number"
                class="form-control"
                placeholder="ยอดฝาก"
              >
            </div>
          </div> -->

          <!-- <div class="col-md-2">
            <div
              class="form-group"
            >
              <label>หมายเหตุ</label>
              <input
                v-model="search_note"
                type="text"
                class="form-control"
                placeholder="หมายเหตุ"
              >
            </div>
          </div> -->
          <!-- <div class="col-md-4">
            <label>วันสมัคร</label>
            <div
              class="form-group"
            >
              <div class="input-group mb-1">
                <span class="input-group-text">วันที่</span>
                <flat-pickr
                  v-model="dateStartstore"
                  class="form-control"
                />
                <span class="input-group-text">ถึง</span>
                <flat-pickr
                  v-model="dateEndstore"
                  class="form-control"
                />
              </div>
            </div>
          </div> -->
          <div class="col-md-12">
            <button
              class="btn btn-gradient-dark"
              @click="GetData()"
            >
              <feather-icon icon="SearchIcon" /> ค้นหา
            </button>
          </div>
        </div>

      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            style="display: flex"
          >
            <b-button
              variant="gradient-primary"
              class="mr-1"
              @click="exportToExcel()"
            >
              โหลดไฟล์ Excel
            </b-button>
            <!-- <b-button

              variant="gradient-danger"
              @click="exportToPdf"
            >
              โหลดไฟล์ PDF
            </b-button> -->
          </div>
        </div>
      </b-card-body>
      <b-table
        ref="table"
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(userID)="data">
          {{ data.item.fullname }}
        </template>
        <template #cell(username)="data">
          {{ data.item.username.username }}
        </template>
        <template #cell(bet_amount)="data">
          {{ Commas(data.item.bet_amount) }}
        </template>
        <template #cell(win_amount)="data">
          {{ Commas(data.item.win_amount) }}
        </template>
        <template #cell(cancel_amount)="data">
          {{ Commas(data.item.cancel_amount) }}
        </template>
        <template #cell(wallet)="data">
          {{ Commas(data.item.balance ) }}
        </template>
        <template #cell(desposits)="data">

          {{ Commas(data.item.desposit) }}

        </template>

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

  </b-overlay>
</template>

<script>
import moment from 'moment-timezone'
import {
  VBTooltip,
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
  // BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    // BInputGroup,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20, 100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',

      options: [
        { value: null, text: 'กรุณาเลือก' },
        { value: '1', text: 'ASKMEBET' },
        { value: '2', text: 'LOTTO' },
        { value: '3', text: 'SBOBET' },

      ],

      fields: [
        { key: 'edit', label: '' },
        { key: 'index', label: 'NO.' },
        { key: 'L', label: '' },
        { key: 'userID', label: 'ชื่อสมาชิก' },
        { key: 'username', label: 'รหัสสมาชิก' },
        { key: 'wallet', label: 'กระเป๋าเงิน' },
        { key: 'desposits', label: 'ยอดฝาก' },
        { key: 'bet_amount', label: 'ยอดเดิมพัน' },
        { key: 'win_amount', label: 'ยอดรางวัล' },
        { key: 'cancel_amount', label: 'ยอดยกเลิก' },
        // { key: 'SBOBET', label: 'SBOBET' },
        // { key: 'lotto', label: 'LOTTO' },
        // { key: 'betflik', label: 'ASKMEBET' },
        { key: 'note', label: 'หมายเหตุ' },
      ],

      items: [
        // {
        //   dateReg: '10/05/2022',
        //   L: '',
        //   username: 'DEV',
        //   userID: 'DEV001',
        //   wallet: '100',
        //   desposit: '10',
        //   dg: '0',
        //   sa: '0',
        //   sbo: '0',
        //   joker: '0',
        //   ww: '0',
        //   lotto: '0',
        //   betflik: '0',
        //   note: 'OK',
        // },
      ],
      dateStart: new Date(),
      dateEnd: new Date(),
      selected: null,
      search_userName: null,
      search_Wallet: 0,
      search_DesAmt: 0,
      search_userID: null,
      dateStartstore: null,
      show: false,
      Product_list: [],
      Game_list: [],
      Lotto_list: [],
      product_id: null,
      game_code: null,
      // lotto_code: null,
    }
  },
  mounted() {
    this.GetData()
    this.GetProduct()
    this.GetGame()
    this.GetLotto()
  },
  methods: {
    time(data) {
      return moment(data).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    GetData() {
      this.show = true
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        selected: this.selected,
        search_userName: this.search_userName,
        search_Wallet: this.search_Wallet,
        search_DesAmt: this.search_DesAmt,
        search_userID: this.search_userID,
        search_note: this.search_note,
        dateStartstore: this.dateStartstore,
        dateEndstore: this.dateEndstore,
        product_id: this.product_id,
        game_code: this.game_code,
        lotto_code: this.lotto_code,
      }
      this.$http.get('/admin/CumulativePlayTotal', { params })
        .then(response => {
          response.data.data.forEach((ele, index) => {
            // eslint-disable-next-line no-param-reassign
            ele.balance = 0
            // eslint-disable-next-line no-param-reassign
            ele.desposit = 0
            this.Getbalances(ele.uid, index)
            this.Getdeposit(ele.uid, index)
          })
          this.items = response.data.data
          this.totalRows = response.data.total
          this.show = false
        }).catch(err => {
          console.log(err)
          this.show = false
        })
    },
    GetProduct() {
      this.$http.get('/product/listall')
        .then(response => {
          this.Product_list = response.data.map(ele => ({ value: ele.productId, text: ele.productName }))
          this.Product_list.unshift({ value: null, text: 'กรุณาเลือก' })
          // console.log(this.Product_list)
        })
        .catch(err => {
          console.log(err)
        })
    },
    GetGame() {
      this.$http.get('/game/listAll')
        .then(response => {
          this.Game_list = response.data
          this.Game_list = response.data.map(ele => ({ value: ele.code, text: ele.name, productId: ele.productId }))
          this.Game_list.unshift({ value: null, text: 'กรุณาเลือก' })
          // console.log(this.Game_list)
        })
        .catch(err => {
          console.log(err)
        })
    },
    GetLotto() {
      this.$http.get('/lotto/listAll')
        .then(response => {
          this.Lotto_list = response.data
          this.Lotto_list = response.data.map(ele => ({ value: ele.id, text: ele.name }))
          this.Lotto_list.unshift({ value: null, text: 'กรุณาเลือก' })
          // console.log(this.Game_list)
        })
        .catch(err => {
          console.log(err)
        })
    },
    FilterGame(ID) {
      if (ID) {
        const GameList = this.Game_list.filter(ele => (ele.productId === ID))
        GameList.unshift({ value: null, text: 'กรุณาเลือก' })
        return GameList
      }
      return this.Game_list
    },
    Getbalances(uid, index) {
      const params = {
        uid,
      }
      this.$http.get('/admin/Getbalance', { params })
        .then(response => {
          this.items[index].balance = response.data.balance
        })
        .catch(err => {
          console.log(err)
        })
    },
    Getdeposit(uid, index) {
      const params = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        uid,
      }
      this.$http.get('/admin/Getdeposits', { params })
        .then(response => {
          this.items[index].desposit = response.data.amountall_deposit
        }).catch(err => {
          console.log(err)
        })
    },
    exportToExcel() {
      this.show = true
      const params = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        selected: this.selected,
        search_userName: this.search_userName,
        search_Wallet: this.search_Wallet,
        search_DesAmt: this.search_DesAmt,
        search_userID: this.search_userID,
        search_note: this.search_note,
        dateStartstore: this.dateStartstore,
        dateEndstore: this.dateEndstore,
      }
      this.$http.get('/admin/export/CumulativePlay', { params })
        .then(response => {
          // console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'ยอดเล่นสะสม.xls' // ระบุชื่อไฟล์ที่ต้องการบันทึก
          link.click()
          this.show = false
        }).catch(err => {
          console.log(err)
          this.show = false
        })
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.input-group-text {
    border-radius: 0;
}

</style>
